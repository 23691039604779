body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text .test {
  color: red;
}

/*# sourceMappingURL=App.css.map */

#navbar-mobile-toggle {
  font-size: 55px;
  margin-top: 2px;
  display: inline-block;
  cursor: pointer;
}
#navbar-mobile-toggle .icons {
  color: #324d5d;
  display: inline;
}
#navbar-mobile-toggle .icons #toggle-normal {
  display: inline;
}
#navbar-mobile-toggle .icons #toggle-hover {
  display: none;
}
#navbar-mobile-toggle #dropdown_logo {
  display: none;
  height: 45px;
}

#subbar {
  text-align: right;
  -ms-text-justify: distribute-all-lines;
  text-justify: distribute-all-lines;
}
#subbar a {
  text-decoration: none;
  text-transform: uppercase;
  color: #e47a36;
}
#subbar ul {
  height: 19px;
  margin-top: 10px;
  margin-bottom: 0px;
}
#subbar li {
  margin: 0px 0px 0px 13px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: inline-block;
  *display: inline;
  zoom: 1;
}
#subbar .invisible {
  display: none;
}
#subbar .navbar-super-small {
  display: none;
}

#navbar-mobile-bar {
  display: none;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 35px;
  padding-right: 35px;
  text-align: justify;
  -ms-text-justify: distribute-all-lines;
  text-justify: distribute-all-lines;
}
#navbar-mobile-bar a {
  text-decoration: none;
  text-transform: uppercase;
  color: #e47a36;
}
#navbar-mobile-bar ul {
  height: 19px;
  margin-bottom: 0px;
}
#navbar-mobile-bar li {
  margin: 0px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: inline-block;
  *display: inline;
  zoom: 1;
}
#navbar-mobile-bar .group {
  padding-bottom: 20px;
}
#navbar-mobile-bar .invisible {
  display: none;
}
#navbar-mobile-bar .navbar-super-small {
  display: none;
}

#navbar_apply {
  display: none;
}

#navbar_footer {
  background-color: #f8f8f8;
}
#navbar_footer .navbar_container {
  margin: 0px auto;
  text-align: justify;
}
#navbar_footer .navbar_container .fake-link {
  text-decoration: none;
  text-transform: uppercase;
  color: #e47a36;
  cursor: pointer;
}
#navbar_footer .navbar_container a {
  text-decoration: none;
  text-transform: uppercase;
  color: #e47a36;
}
#navbar_footer .navbar_container ul {
  margin-bottom: 0px;
}
#navbar_footer .navbar_container li {
  width: 32%;
  margin: 0px auto;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  *display: inline;
  zoom: 1;
}
#navbar_footer .level1 {
  max-width: 300px;
  padding: 20px 20px 20px;
}
#navbar_footer .level2 {
  max-width: 600px;
  padding: 0px 20px 20px;
  text-align: center;
}
#navbar_footer .hidden {
  display: none;
}
.stretch {
  width: 100%;
  display: inline-block;
  font-size: 0;
  line-height: 0;
}

/* Medium screens (768px) */
@media screen and (min-width: 480px) {
  #navbar_apply {
    display: inline-block;
  }

  #navbar-mobile-toggle #dropdown_logo {
    display: inline;
    height: 45px;
  }

  #navbar-mobile-bar .navbar-super-small {
    display: inline-block;
  }
}
/* Medium screens (768px) */
@media screen and (min-width: 768px) {
  #navbar-mobile-toggle {
    display: none;
  }
}
/* Large screens (1313px) */

/*# sourceMappingURL=navbar.css.map */

/* RESET
 * Reset default browser CSS. Based on work by Eric Meyer:
 * http://meyerweb.com/eric/tools/css/reset/index.html
 * ---------------------------------------------------------------------- */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

body {
  line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ol,
ul {
  margin-bottom: 10px;
}

ol li,
ul li {
  margin-left: 22px;
}

blockquote {
  quotes: none;
}

blockquote:before,
blockquote:after {
  content: "";
  content: none;
}

sup {
  font-size: 0.6em;
  vertical-align: baseline;
  position: relative;
  top: -0.6em;
}

sub {
  font-size: 0.6em;
  vertical-align: baseline;
  position: relative;
  bottom: -0.4em;
}

del {
  text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Temporary Code - Note Christine's new code clears using containers */
.clear {
  clear: both;
}

/* float clearing */
.group:after,
.container:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.group,
.container {
  display: inline-block;
}

/* Hides from IE-mac */
* html .group,
* html .container {
  height: 1%;
}

.group,
.container {
  display: block;
}

.ui-tabs-hide {
  display: none;
}

/* End hide from IE-mac */
.applysec1hidden {
  font-size: 24px;
  padding: 26px 25px;
  border: 1px solid #e47a36;
  background-color: #e47a36;
  color: #fff;
  font-family: "Proxima Nova Light";
  text-transform: uppercase;
}

body.blackbg {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #344c5a;
  background-size: cover;
  font: 15px/26px "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 200;
  color: #4a6f7d;
  -webkit-font-smoothing: antialiased;
  /* Fix for webkit rendering */
  -webkit-text-size-adjust: 100%;
}

/* TYPOGRAPHY & FONT COLORS
 * Font styling and colors
 * ---------------------------------------------------------------------- */
@font-face {
  font-family: "Proxima Nova";
  src: url(/static/media/ProximaNova-Reg-webfont.d9d162d5.eot);
  src: url(/static/media/ProximaNova-Reg-webfont.d9d162d5.eot?#iefix) format("embedded-opentype"), url(/static/media/ProximaNova-Reg-webfont.c2822d0a.woff) format("woff"), url(/static/media/ProximaNova-Reg-webfont.ab6b9b83.ttf) format("truetype"), url(/static/media/ProximaNova-Reg-webfont.f38544e7.svg#proxima_nova_rgregular) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova Light";
  src: url(/static/media/ProximaNova-Light-webfont.c4d42ad6.eot);
  src: url(/static/media/ProximaNova-Light-webfont.c4d42ad6.eot?#iefix) format("embedded-opentype"), url(/static/media/ProximaNova-Light-webfont.9ff315e0.ttf) format("truetype"), url(/static/media/ProximaNova-Light-webfont.cd2d9551.svg#proxima_nova_light) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body {
  font-family: "Proxima Nova Light";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Proxima Nova";
  font-weight: normal;
}

h1 {
  font-size: 36px;
  line-height: 1.2;
  margin-bottom: 20px;
}

h2 {
  font-size: 36px;
  line-height: 1.2;
  margin-bottom: 20px;
}

h3 {
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 20px;
}

h4 {
  font-size: 21px;
  line-height: 1.2;
  margin-bottom: 20px;
}

p {
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 18px;
}

p.large {
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 30px;
}

hr {
  display: block;
  height: 2px;
  border: none;
  margin: 10px 0;
  color: #d2d2d2;
  background-color: #d2d2d2;
}

.button {
  display: inline-block;
  font-family: "Proxima Nova Light";
  text-transform: uppercase;
  padding: 17px 25px;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -webkit-appearance: none;
}
.button:hover {
  background-color: #e47a36;
  color: #fff;
}
.button:hover a {
  color: #fff;
}
.button.primary {
  display: block;
  font-size: 24px;
  text-align: center;
  padding: 26px 25px;
  border: 1px solid #e47a36;
  background-color: #e47a36;
  color: #fff;
}
.button.primary:hover {
  background-color: #fff;
  color: #e47a36;
  border: 1px solid #e47a36;
  cursor: pointer;
}
.button.secondary {
  font-size: 16px;
  background-color: #e47a36;
  border: 1px solid #e47a36;
  color: #fff;
}
.button.secondary:hover {
  background-color: #fff;
  color: #e47a36;
}

/* LAYOUT
 * Overall page layout
 * ---------------------------------------------------------------------- */
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  background-color: #324d5d;
  color: #344c5a;
}

.mobile {
  display: block;
}

.desktop {
  display: none;
}

.content-container {
  width: 100%;
  max-width: 1249px;
  margin: 0 auto;
}

.content {
  padding: 32px;
}

/* HEADER
 * Headers styling
 * ---------------------------------------------------------------------- */
header[role=banner] {
  background-color: #fff;
}
header[role=banner] .content {
  padding: 20px 30px;
}
header[role=banner] .logo {
  float: left;
  width: 123px;
  height: 54px;
}
header[role=banner] nav {
  float: right;
}

/* FOOTER
 * Footer page layout
 * ---------------------------------------------------------------------- */
footer[role=contentinfo] {
  background-color: #3fb39d;
}
footer[role=contentinfo] .content {
  padding-top: 100px;
  padding-bottom: 100px;
}
footer[role=contentinfo] h1,
footer[role=contentinfo] h2,
footer[role=contentinfo] h3,
footer[role=contentinfo] h4,
footer[role=contentinfo] h5,
footer[role=contentinfo] h6,
footer[role=contentinfo] p {
  color: #fff;
}
footer[role=contentinfo] a {
  color: #fff;
  text-decoration: none;
}
footer[role=contentinfo] a:hover {
  color: #fff;
  text-decoration: underline;
}
footer[role=contentinfo] img {
  margin-bottom: 20px;
}
footer[role=contentinfo] .column {
  margin-bottom: 70px;
}
footer[role=contentinfo] .section-copyright {
  background-color: #324d5d;
  text-align: center;
}
footer[role=contentinfo] .section-copyright p.copyright {
  margin-top: 40px;
}

/* NAV
 * Nav styling
 * ---------------------------------------------------------------------- */
nav.nav-main {
  padding-top: 4px;
}
nav.nav-main a {
  text-decoration: none;
  text-transform: uppercase;
  color: #e47a36;
}
nav.nav-main ul {
  float: right;
}
nav.nav-main li {
  margin-left: 10px;
  display: inline-block;
}
nav.nav-main li.desktop {
  display: none;
}

/* PANELS
 * Panel styling
 * ---------------------------------------------------------------------- */
.section-panel {
  position: relative;
}
.section-panel.bg-dark {
  background-color: #324d5d;
}
.section-panel.bg-dark h1,
.section-panel.bg-dark h2,
.section-panel.bg-dark h3,
.section-panel.bg-dark h4,
.section-panel.bg-dark h5,
.section-panel.bg-dark h6,
.section-panel.bg-dark p {
  color: #fff;
}
.section-panel.bg-grey {
  background-color: #4e4e4e;
}
.section-panel.bg-grey h1,
.section-panel.bg-grey h2,
.section-panel.bg-grey h3,
.section-panel.bg-grey h4,
.section-panel.bg-grey h5,
.section-panel.bg-grey h6,
.section-panel.bg-grey p {
  color: #fff;
}
.section-panel.bg-yellow {
  background-color: #e9c942;
}
.section-panel.bg-yellow h1,
.section-panel.bg-yellow h2,
.section-panel.bg-yellow h3,
.section-panel.bg-yellow h4,
.section-panel.bg-yellow h5,
.section-panel.bg-yellow h6,
.section-panel.bg-yellow p {
  color: #fff;
}
.section-panel.bg-light-grey {
  background-color: #a0a0a0;
}
.section-panel.bg-light-grey h1,
.section-panel.bg-light-grey h2,
.section-panel.bg-light-grey h3,
.section-panel.bg-light-grey h4,
.section-panel.bg-light-grey h5,
.section-panel.bg-light-grey h6,
.section-panel.bg-light-grey p {
  color: #fff;
}
.section-panel.bg-teal {
  background-color: #3fb39d;
}
.section-panel.bg-teal h1,
.section-panel.bg-teal h2,
.section-panel.bg-teal h3,
.section-panel.bg-teal h4,
.section-panel.bg-teal h5,
.section-panel.bg-teal h6,
.section-panel.bg-teal p {
  color: #fff;
}
.section-panel.bg-white {
  background-color: #fff;
}
.section-panel.bg-white h1,
.section-panel.bg-white h2,
.section-panel.bg-white h3,
.section-panel.bg-white h4,
.section-panel.bg-white h5,
.section-panel.bg-white h6 {
  color: #279ca2;
}
.section-panel.bg-white p {
  color: #52636b;
}
.section-panel.bg-beige {
  background-color: #f8f8f8;
}

#section-welcome p.large {
  opacity: 0.69;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=69)";
  filter: alpha(opacity=69);
  -moz-opacity: 0.69;
  -khtml-opacity: 0.69;
}
#section-welcome .compare {
  margin-top: -15px;
}
#section-welcome .compare .content {
  padding-top: 0;
  padding-bottom: 10px;
}
#section-welcome .compare p {
  font-size: 24px;
  margin: 0;
}
#section-welcome .aside-loan-calculator {
  background-color: #fff;
}
#section-welcome .aside-loan-calculator .content {
  padding: 28px;
}
#section-welcome .aside-loan-calculator p {
  font-size: 14px;
  color: #2398a0;
}
#section-welcome .aside-loan-calculator h3 {
  color: #2398a0 !important;
}
#section-welcome .aside-loan-calculator input[type=submit] {
  display: block;
  width: 100%;
  margin: 30px 0px;
}
#section-welcome .aside-loan-calculator #state_selector h4 {
  color: #e47a36;
  text-align: center;
}
#section-welcome .aside-loan-calculator #state_selector select {
  width: 100%;
  height: 43px;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  color: #777;
  margin-bottom: 15px;
  background-color: #fafafa;
  border: 1px solid #3fb39d;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  box-sizing: border-box;
}
#section-welcome .aside-loan-calculator #state_selector .application_errors {
  background-color: #d0021b;
  margin-bottom: 15px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  display: none;
}
#section-welcome .aside-loan-calculator #state_selector .application_errors .messages,
#section-welcome .aside-loan-calculator #state_selector .application_errors p {
  font-size: 16px;
  padding: 10px;
  margin: 0;
  color: #ffffff;
}
#section-welcome .aside-loan-calculator .disclosures {
  display: block;
  text-align: center;
  color: #868686;
}
#section-welcome .aside-loan-calculator .slider-full .ui-slider-horizontal {
  height: 10px;
  margin-bottom: 20px;
}
#section-welcome .aside-loan-calculator .slider-full .ui-slider-handle {
  display: block;
  width: 100%;
  top: -11px;
  width: 32px;
  height: 32px;
  background: #fff;
  border: 0px;
  border-radius: 32px;
  box-shadow: 0 4px 12px 0px #999;
  -webkit-box-shadow: 0 4px 12px 0px #999;
  -moz-box-shadow: 0 4px 12px 0px #999;
}
#section-welcome .aside-loan-calculator .slider-full .ui-slider-handle:hover {
  background: #93aec2;
}
#section-welcome .aside-loan-calculator .slider-full .ui-slider-handle:active {
  background: #93aec2;
}
#section-welcome .aside-loan-calculator .slider-full .slider_axis_left,
#section-welcome .aside-loan-calculator .slider-full .slider_axis_right {
  font-size: 20px;
  color: #2398a0;
}
#section-welcome .aside-loan-calculator .slider-full .slider_axis_left {
  float: left;
}
#section-welcome .aside-loan-calculator .slider-full .slider_axis_right {
  float: right;
}
#section-welcome .aside-loan-calculator .sliders_right {
  margin: 20px 0;
}
#section-welcome .aside-loan-calculator .sliders_right p {
  float: left;
  width: 33%;
}
#section-welcome .aside-loan-calculator .sliders_right span {
  font-family: "Proxima Nova";
  font-size: 20px;
}
#section-welcome .aside-loan-calculator .ui-widget-content {
  background: #dedede;
  border: 1px solid #dedede;
}

#section-features .arrow {
  position: absolute;
  top: 0;
  left: 30px;
}
#section-features .column.third {
  text-align: center;
  margin-bottom: 90px;
}
#section-features .column.half {
  text-align: center;
  margin-bottom: 90px;
}
#section-features .faq-links {
  margin: 0px 40px;
}
#section-features .icon {
  width: 200px;
  height: 200px;
  margin: 0 auto 35px;
  text-align: center;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  background-color: #fff;
}
#section-features .icon img {
  margin-top: 70px;
}
#section-features .diagram {
  width: 200px;
  height: 250px;
  margin: 0 auto 35px;
  text-align: center;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  background-color: #fff;
}
#section-features .diagram img {
  width: 100%;
  margin-top: 50px;
}
#section-features p {
  margin-bottom: 0;
}
#section-features p.faq {
  margin: 10px auto;
  font-size: 15px;
  line-height: 1.5;
}

/* Super Small Screens (< 400px) */
@media screen and (max-width: 420px) {
  #section-welcome .aside-loan-calculator .sliders_right {
    margin: 20px 0;
  }
  #section-welcome .aside-loan-calculator .sliders_right p {
    font-size: 18px;
    width: 50%;
  }
  #section-welcome .aside-loan-calculator .sliders_right span {
    font-size: 18px;
  }
}
/* Medium screens (768px) */
@media screen and (min-width: 768px) {
  /* TYPOGRAPHY & FONT COLORS
   * Font styling and colors
   * ---------------------------------------------------------------------- */
  p {
    font-size: 14px;
    line-height: 1.2;
  }

  p.large {
    font-size: 20px;
  }

  h1 {
    font-size: 52px;
  }

  /* LAYOUT
   * Overall page layout
   * ---------------------------------------------------------------------- */
  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  /* HEADERS
   * Header styling
   * ---------------------------------------------------------------------- */
  header[role=banner] .logo {
    width: 217px;
    height: 95px;
  }

  /* FOOTERS
   * Footer styling
   * ---------------------------------------------------------------------- */
  footer[role=contentinfo] {
    background-color: #435b69;
  }

  /* NAV
   * Nav styling
   * ---------------------------------------------------------------------- */
  nav.nav-main {
    margin-top: 30px;
    padding-top: 0px;
  }
  nav.nav-main li.desktop {
    display: inline-block;
  }

  /* PANELS
   * Panel styling
   * ---------------------------------------------------------------------- */
  .section-panel .column.left {
    float: left;
    width: 40%;
  }
  .section-panel.bg-white h1,
.section-panel.bg-white h2,
.section-panel.bg-white h3,
.section-panel.bg-white h4,
.section-panel.bg-white h5,
.section-panel.bg-white h6 {
    color: #344c5a;
  }

  #section-welcome p.large {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
  }
  #section-welcome .compare p {
    float: right;
    width: 56%;
    max-width: 614px;
    font-size: 30px;
  }
  #section-welcome .opaque {
    opacity: 0.69;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=69)";
    filter: alpha(opacity=69);
    -moz-opacity: 0.69;
    -khtml-opacity: 0.69;
  }
  #section-welcome .aside-loan-calculator {
    float: right;
    width: 56%;
    max-width: 614px;
  }
  #section-welcome .aside-loan-calculator h3 {
    font-size: 30px;
    color: #e47a36 !important;
  }
  #section-welcome .aside-loan-calculator .disclosures {
    color: #3fb39d;
  }

  #section-features .arrow {
    left: 57%;
  }
  #section-features .column.third {
    float: left;
    width: 23.33%;
    margin: 0 5%;
  }
  #section-features .column.half {
    float: right;
    width: 40%;
    margin: 0 5%;
  }
  #section-features .icon {
    width: 150px;
    height: 150px;
  }
  #section-features .icon img {
    margin-top: 50px;
  }
  #section-features .diagram {
    width: 250px;
    height: 300px;
  }
  #section-features .diagram img {
    width: 100%;
    margin-top: 50px;
  }
  #section-features p {
    font-size: 18px;
  }
  #section-features p.faq {
    margin: 10px auto;
    font-size: 15px;
    line-height: 1.5;
  }

  /* FOOTERS
   * Footer styling
   * ---------------------------------------------------------------------- */
  footer[role=contentinfo] .column.about {
    float: left;
    width: 40%;
  }
  footer[role=contentinfo] .column.disclosures {
    float: left;
    width: 20%;
    margin-left: 13%;
  }
  footer[role=contentinfo] .column.social {
    float: right;
    width: 25%;
  }
  footer[role=contentinfo] p {
    font-size: 16px;
  }
}
/* Large screens (1313px) */
@media screen and (min-width: 1313px) {
  /* TYPOGRAPHY & FONT COLORS
   * Font styling and colors
   * ---------------------------------------------------------------------- */
  p.large {
    font-size: 24px;
  }

  h1 {
    font-size: 68px;
    margin-bottom: 50px;
  }

  h2 {
    font-size: 40px;
  }

  /* LAYOUT
   * Overall page layout
   * ---------------------------------------------------------------------- */
  .content {
    padding: 70px 0;
  }

  /* HEADERS
   * Header styling
   * ---------------------------------------------------------------------- */
  header[role=banner] .content {
    padding: 20px 0;
  }

  /* FOOTERS
   * Footer styling
   * ---------------------------------------------------------------------- */
  footer[role=contentinfo] .section-copyright .content {
    padding: 30px 0;
  }
  footer[role=contentinfo] .section-copyright p.copyright {
    float: left;
    margin-top: 0;
    margin-bottom: 0;
  }
  footer[role=contentinfo] .section-copyright .terms {
    float: right;
    margin-bottom: 0;
  }

  /* SECTION
   * Section styling
   * ---------------------------------------------------------------------- */
  #section-welcome {
    background: #324d5d;
    background-position: top left;
    background-size: cover;
  }
  #section-welcome .compare .content {
    padding-top: 0;
    padding-bottom: 15px;
    margin-top: -55px;
  }
  #section-welcome .aside-loan-calculator p {
    font-size: 18px;
    color: #52636b;
  }
  #section-welcome .aside-loan-calculator .slider-full p {
    color: #2398a0;
  }
  #section-welcome .aside-loan-calculator .slider-full .slider_axis_left,
#section-welcome .aside-loan-calculator .slider-full .slider_axis_right {
    color: #52636b;
  }
  #section-welcome .aside-loan-calculator .sliders_right {
    margin: 20px 0;
  }
  #section-welcome .aside-loan-calculator .sliders_right p {
    font-size: 16px;
    float: left;
    width: 20%;
  }
  #section-welcome .aside-loan-calculator .sliders_right p#slider_apr_label {
    margin-left: 10px;
    width: 15%;
  }
  #section-welcome .aside-loan-calculator .sliders_right span {
    display: inline-block;
    font-size: 20px;
    margin-top: 10px;
  }
  #section-welcome .aside-loan-calculator .sliders_right #length_label_left + span {
    font-size: 20px;
  }
  #section-welcome .aside-loan-calculator input[type=submit] {
    float: left;
    width: 50%;
    margin: 10px 0px 30px 0px;
  }
  #section-welcome .aside-loan-calculator .disclosures {
    float: right;
    font-size: 18px;
    margin-top: 45px;
  }

  #section-features .icon {
    width: 200px;
    height: 200px;
  }
  #section-features .icon img {
    margin-top: 70px;
  }
}
.disabled {
  pointer-events: none;
  background-color: grey !important;
  border-color: grey !important;
}

/*# sourceMappingURL=styles.css.map */

.application {
  color: #fff;
}
.application .loan_edit {
  line-height: 1.2;
  background-color: #324d5d;
  color: #fff;
}
.application .loan_edit .content {
  padding: 20px;
}
.application .loan_edit .mobile-break {
  display: block;
}
.application .loan_edit #loan_summary {
  float: left;
}
.application .loan_edit #loan_edit_button {
  cursor: pointer;
  float: right;
  font-size: 14px;
  text-transform: uppercase;
  padding: 5px 20px;
  margin-top: 5px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
.application .loan_edit .sliders_right {
  margin: 0;
}
.application .application_errors {
  background-color: #d0021b;
  margin-bottom: 15px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  color: #fff;
}
.application .application_errors .messages,
.application .application_errors p {
  font-size: 16px;
  padding: 10px;
  margin: 0;
}
.application .application_errors.reset_password {
  display: block;
  padding: 10px;
  font-size: 16px;
  margin: 0px 0px 15px 0px;
}
.application .application_errors.reset_password ul {
  margin: 5px 0px;
}
.application .form-container {
  position: relative;
  padding: 10px 25px 0 25px;
  background-color: #fff;
  color: #5c686f;
}
.application .form-container h2 {
  font-size: 24px;
  color: #324d5d;
  margin-bottom: 5px;
}
.application .form-container h3 {
  font-size: 16px;
  color: #324d5d;
  margin-bottom: 5px;
}
.application .form-container input[type=text],
.application .form-container input[type=password],
.application .form-container input[type=date] {
  width: 100%;
  font-size: 18px;
  font-family: "Proxima Nova Light";
  font-weight: 300;
  padding: 10px;
  color: #777;
  margin-bottom: 15px;
  background-color: #fafafa;
  border: 1px solid #3fb39d;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  box-sizing: border-box;
}
.application .form-container .teal {
  color: #3fb39d;
}
.application .form-container .readonly input[type=text],
.application .form-container .readonly input[type=date] {
  color: #3fb39d;
}
.application .form-container input[type=date] {
  padding: 10px 10px;
  margin-bottom: 9px;
}
.application .form-container input[type=radio] {
  margin: 5px 10px 5px 10px;
}
.application .form-container select {
  width: 100%;
  height: 43px;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
  color: #777;
  margin-bottom: 15px;
  background-color: #fafafa;
  border: 1px solid #3fb39d;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  box-sizing: border-box;
}
.application .form-container .blog_tab {
  display: inline-block;
  float: left;
  font-size: 14px;
  text-align: left;
  padding-right: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.application .form-container .blog_tab p,
.application .form-container .blog_tab a {
  color: #324d5d;
  text-decoration: none;
}
.application .form-container .blog_tab h3 {
  color: #e47a36;
}
.application .form-container .blog_tab .constricted_image {
  max-width: 100%;
}
.application .form-container .percent33 {
  width: 33%;
}
.application .form-container .percent50 {
  width: 50%;
}
.application .form-container .subheader {
  font-size: 14px;
  line-height: 1.4;
  color: #000;
  padding: 20px;
  background-color: #efefef;
  border: 1px solid #c8c8c8;
  border-bottom: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-bottom: 0;
}
.application .form-container .subheader .highlighted_phone_number {
  text-align: center;
  font-size: 20px;
  margin-bottom: 5px;
}
.application .form-container .yellow_box {
  font-size: 16px;
  line-height: 1.4;
  color: #000;
  margin-bottom: 18px;
  text-align: center;
  padding: 20px;
  background-color: #ffffe6;
  border: 1px solid #c8c8c8;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.application .form-container .yellow_box a.full_link {
  color: #324d5d;
  text-decoration: underline;
  cursor: pointer;
}
.application .form-container .yellow_box a.full_link:hover {
  color: #5c686f;
  text-decoration: none;
  text-shadow: none;
}
.application .form-container .yellow_box a.full_link:visited {
  color: #5c686f;
  text-decoration: none;
  text-shadow: none;
}
.application .form-container .confirmations {
  background-color: #3fb39d;
  margin-bottom: 15px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  color: #fff;
}
.application .form-container .confirmations .messages,
.application .form-container .confirmations p {
  font-size: 16px;
  padding: 10px;
  margin: 0;
}
.application .form-container .errors {
  background-color: #d0021b;
  margin-bottom: 15px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  color: #fff;
}
.application .form-container .errors .messages,
.application .form-container .errors p {
  font-size: 16px;
  padding: 10px;
  margin: 0;
}
.application .form-container .form_header {
  margin-bottom: 20px;
}
.application .form-container .form_footer {
  margin: 20px -25px;
  padding: 20px 25px;
  text-align: center;
  background-color: #efefef;
}
.application .form-container .form_footer a {
  cursor: pointer;
  display: inline-block;
  line-height: 24px;
  padding-left: 24px;
  color: #324d5d;
  text-decoration: underline;
  background: url("/icon_lock.png") left top no-repeat;
}
.application .form-container .form_footer a:hover {
  color: #5c686f;
}
.application .form-container p.important {
  color: #d0021b;
}
.application .form-container #overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #fff;
  opacity: 0.7;
  display: none;
}
.application .form-container #esign_cso {
  font-size: 14px;
  line-height: 1.4;
  color: #000;
  padding: 20px;
  background-color: #ffffe6;
  border: 1px solid #c8c8c8;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.application .form-container #expectations {
  padding: 20px 0px;
}
.application .form-container #expectations #ap_loan_sliders {
  display: none;
}
.application .form-container #expectations #change_amount {
  color: #324d5d;
}
.application .form-container #expectations #change_amount a {
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
}
.application .form-container #blog_links {
  padding: 20px 0px;
}
.application .form-container #questionaire {
  color: #324d5d;
}
.application .form-container #questionaire .question {
  margin-bottom: 15px;
}
.application .form-container .button.primary {
  display: block;
  width: 100%;
  padding: 15px 0;
  line-height: 30px;
}
.application .form-container hr {
  display: block;
  height: 1px;
  border: none;
  background-color: #d2d2d2;
  margin: 10px 0 20px;
}
.application .form-container #new_user_esign,
.application .form-container #existing_user_form {
  display: none;
}
.application #approval_pending {
  padding-top: 20px;
}
.application #progress_list {
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 20px;
  margin-bottom: 15px;
}
.application #progress_list li {
  position: relative;
  float: left;
  width: 20%;
  margin: 0;
  text-align: center;
  list-style: none;
  color: #d8d8d8;
}
.application #progress_list li .circle {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  border-radius: 40px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
  background-color: #d8d8d8;
  color: #fff;
  z-index: 2;
}
.application #progress_list li.done {
  color: #e47a36;
}
.application #progress_list li.done .bg-stripe {
  position: absolute;
  top: 17px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #e47a36;
  z-index: 1;
}
.application #progress_list li.done .circle {
  background-color: #e47a36;
}
.application #progress_list li.done:first-child .bg-stripe {
  left: 50%;
}
.application #progress_list li.done.last .bg-stripe {
  width: 50%;
}
.application #step4_middle {
  display: none;
}
.application #loan_edit_sliders {
  color: #2398a0;
  background-color: #efefef;
}
.application #loan_edit_sliders button {
  width: 100%;
  padding: 10px;
}
.application #loan_edit_sliders h3 {
  color: #324d5d;
}
.application #loan_edit_sliders span {
  color: #324d5d;
}
.application #loan_edit_sliders.visible + .content {
  background-color: #efefef;
}
.application .checkfull {
  overflow: hidden;
  margin: 0px auto 20px auto;
  max-width: 436px;
}
.application .messages .message {
  margin-top: 5px;
}
.application a#switch_to_personal {
  display: block;
  margin: 0 auto 20px;
  text-align: center;
  text-decoration: underline;
  color: #324d5d;
  cursor: pointer;
}
.application a#show_dnc {
  text-align: center;
  cursor: pointer;
  display: inline-block;
  line-height: 24px;
  color: #324d5d;
  text-decoration: underline;
}
.application a#show_dnc:hover {
  color: #5c686f;
}
.application a#show_non_dnc {
  text-align: center;
  cursor: pointer;
  display: inline-block;
  line-height: 24px;
  color: #324d5d;
  text-decoration: underline;
}
.application a#show_non_dnc:hover {
  color: #5c686f;
}
.application a#hide_change_number {
  text-align: center;
  cursor: pointer;
  display: inline-block;
  line-height: 24px;
  color: #324d5d;
  text-decoration: underline;
}
.application a#hide_change_number:hover {
  color: #5c686f;
}

/* LOAN SLIDER STYLES
 * Applies to home page and application slider
 * ---------------------------------------------------------------------- */
.slider-full p {
  color: #2398a0;
}
.slider-full .slider_axis_left,
.slider-full .slider_axis_right {
  font-size: 20px;
  color: #324d5d;
}
.slider-full .ui-slider-horizontal {
  height: 10px;
  margin-bottom: 20px;
}
.slider-full .ui-widget-content {
  background: #dedede;
  border: 1px solid #dedede;
}
.slider-full .ui-slider-handle {
  display: block;
  width: 100%;
  top: -11px !important;
  width: 32px !important;
  height: 32px !important;
  background: #fff !important;
  border: 0px !important;
  border-radius: 32px;
  box-shadow: 0 4px 12px 0px #999 !important;
  -webkit-box-shadow: 0 4px 12px 0px #999 !important;
  -moz-box-shadow: 0 4px 12px 0px #999 !important;
}
.slider-full .ui-slider-handle:hover {
  background: #93aec2;
}
.slider-full .ui-slider-handle:active {
  background: #93aec2;
}
.slider-full .slider_axis_left {
  float: left;
}
.slider-full .slider_axis_right {
  float: right;
}

.sliders_right {
  margin: 20px 0;
}
.sliders_right p {
  float: left;
  width: 50%;
}
.sliders_right span {
  font-family: "Proxima Nova";
  font-size: 24px;
}

.ui-widget-content {
  background: #dedede;
  border: 1px solid #dedede;
}

/* Medium screens (768px) */
@media screen and (min-width: 768px) {
  .application h1 {
    text-align: center;
  }
  .application .loan_edit #your_loan_summary {
    margin-top: 6px;
  }
  .application .loan_edit .mobile-break {
    display: inline;
  }
  .application .loan_edit #loan_edit_button {
    margin-top: 0;
  }
  .application .content {
    margin: 0 auto;
    max-width: 556px;
  }
  .application #progress_list li .circle {
    margin-bottom: 20px;
  }
  .application .formleft {
    float: left;
    width: 48%;
  }
  .application .formright {
    float: right;
    width: 48%;
  }
}
/* Large screens (1313px) */
@media screen and (min-width: 1313px) {
  .application .content {
    padding: 32px;
  }
  .application h1 {
    font-size: 52px;
    text-align: center;
    margin-bottom: 20px;
  }
}
.fa-check:hover,
.fa-times:hover {
  cursor: pointer;
}

.activate {
  background-color: #33c949 !important;
  border: 1px solid #33c949 !important;
}

.activate:hover,
.activate:active {
  background-color: white !important;
  border: 1px solid #33c949 !important;
  color: #33c949 !important;
}

.deactivate {
  background-color: red !important;
  border: 1px solid red !important;
}

.deactivate:hover,
.deactivate:active {
  background-color: white !important;
  border: 1px solid red !important;
  color: red !important;
}

/*# sourceMappingURL=loan_application.css.map */

.ui-link {
  color: white;
}

/* Header spacing */
.header-padding {
  padding-bottom: 50px;
}

.no-text-shadow {
  text-shadow: none;
}

#into_headline {
  color: #ffffff;
  text-shadow: 3px 3px 0px #000;
}

.home_placeholder {
  position: fixed;
  bottom: 50%;
}
.home_placeholder h3 {
  font-style: bold;
  font-size: 25pt;
  color: white;
}

.home_filter {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #b0c4de;
  opacity: 0.85;
}

.form_text_center {
  padding-top: 20px;
  margin: auto;
  text-align: center;
}
.form_text_center a {
  color: #324d5d;
}
.form_text_center a:hover {
  color: #5c686f;
  cursor: pointer;
}

#existing_user_form {
  display: none;
}

#plaid-generic-link {
  text-decoration: underline;
}

.primary_verification_button {
  -moz-border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -webkit-appearance: none !important;
  display: inline-block !important;
  font-family: "Proxima Nova Light" !important;
  text-transform: uppercase !important;
  width: 100% !important;
  margin: 10px auto !important;
  padding: 16px 25px !important;
  border: 1px solid #d4d4d4 !important;
  border-radius: 10px !important;
  display: block !important;
  font-size: 24px !important;
  border: 1px solid #e47a36 !important;
  background-color: #e47a36 !important;
  color: #fff !important;
}
.primary_verification_button:hover {
  background-color: #fff !important;
  color: #e47a36 !important;
  border: 1px solid #e47a36 !important;
  cursor: pointer !important;
}
.primary_verification_button a {
  text-decoration: none;
  color: #fff;
}
.primary_verification_button a:hover {
  color: #e47a36;
}

.underline_link {
  text-decoration: underline;
  color: #324d5d;
}
.underline_link:hover {
  color: #5c686f;
  cursor: pointer;
}

.pseudo_submit {
  background: #628552;
  display: block;
  text-align: center;
  margin: 10px auto;
  height: 30px;
  width: 85px;
  padding: 10px 20px 10px 20px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 300;
  text-decoration: none;
  border: none;
  border-radius: 10px;
}
.pseudo_submit:hover {
  background: #6fafc8;
}

.white_text {
  color: white;
  margin-bottom: 26px;
}

.white_link:hover {
  color: #6fafc8;
}

.center {
  text-align: center;
}

p.subheader {
  color: white;
  margin-top: 0px;
  font-size: 24px;
  font-weight: 250;
}

.norton_center {
  margin: auto;
  margin-top: 20px;
  width: 135px;
}
.norton_center table {
  border: 0px;
}
.norton_center table td {
  border: 0px;
}

header.dashboard {
  width: 100%;
  height: 300px;
  background-color: #344c5a;
  background-size: cover;
}

.nav_green a {
  color: #496f7d;
}
.nav_green a:hover {
  color: #628552;
}

.nav_white a {
  color: white;
}
.nav_white a:hover {
  color: #285d86;
}

.alert {
  background-color: rgba(238, 185, 185, 0.8);
  border-color: #e06868;
}

.notice {
  background-color: rgba(51, 153, 204, 0.6);
  border-color: #000066;
}

.flash {
  width: 60%;
  text-align: center;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  margin: 10px auto 10px auto;
  padding: 5px 0px;
  color: white;
}

.invisible {
  display: none;
}

.width-80 {
  width: 80%;
  margin: 0px auto;
}

/*# sourceMappingURL=custom.css.map */

.administrative-dashboard {
  margin-left: auto;
  margin-right: auto;
  font-family: "Open Sans", "Helvetica Neue", Arial;
  padding: 25px;
  max-width: 1400px;
}

.portfolio-performance {
  padding-bottom: 20px;
}
.portfolio-performance__title {
  padding: 30px 25px 0px 25px;
}
.portfolio-performance__title--id,
.portfolio-performance__title--name,
.portfolio-performance__title--date,
.portfolio-performance__title--amount,
.portfolio-performance__title--comment,
.portfolio-performance__title--referrer,
.portfolio-performance__title--term {
  font-size: 12px;
  color: #088772;
  font-weight: 600;
}
.portfolio-performance__charts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: 100%;
}
.portfolio-performance__charts > .chart {
  display: inline-block;
  margin: 10px 0 10px 2%;
  flex-grow: 1;
  width: calc(100% * (1 / 4) - 10px - 1px);
  font-size: 13px;
  color: #324d5d;
  background-color: #fff;
  padding: 25px 25px 45px 25px;
  border-radius: 5px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.05),
    0 6px 20px 0 rgba(0, 0, 0, 0.05) !important;
}

.loan-performance {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: 100%;
}
.loan-performance__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0 10px 2%;
  flex-grow: 1;
  width: calc(100% * (1 / 4) - 80px);
  font-size: 13px;
  color: #324d5d;
  background-color: #fff;
  padding: 25px 25px 10px 25px;
  border-radius: 5px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.05),
    0 6px 20px 0 rgba(0, 0, 0, 0.05) !important;
  cursor: pointer;
}
.loan-performance__item.hidden-item {
  display: none;
}
.loan-performance__item:hover,
.loan-performance__item.selected {
  background-color: #eeeeee;
}
.loan-performance__item.selected {
  cursor: inherit;
}
.loan-performance__item i {
  display: flex;
  width: 75px;
  height: 75px;
  background: url("/banana.svg");
}
.loan-performance__item i.apple {
  background: url("/apple.svg");
}
.loan-performance__item i.pear {
  background: url("/pear.svg");
}

.loan-info {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 2%;
  flex-grow: 1;
  width: auto;
  font-size: 13px;
  color: #324d5d;
  background-color: #fff;
  padding: 25px 25px 10px 25px;
  border-radius: 5px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.05),
    0 6px 20px 0 rgba(0, 0, 0, 0.05) !important;
  position: relative;
}
.loan-info.selected {
  display: flex;
}
.loan-info__main {
  width: 100%;
}
.loan-info__main > .history {
  height: 400px;
  width: 100%;
  margin: 0px;
  padding: 0 0 90px 0px;
  display: none;
}
.loan-info__main > .history > div {
  height: 350px;
}
.loan-info__main > .history > div > .account-selector {
  margin: 0 2px;
}
.loan-info__main > .history > div > .account-selector select {
  background: white;
  border-radius: 3px;
  padding: 7px;
  color: #324d5d;
  width: auto;
}
.loan-info__main > .history > div > .account-table {
  margin-top: 5px;
  table-layout: fixed;
}
.loan-info__main > .history > div > .account-table table {
  width: 100%;
}
.loan-info__main > .history > div > .account-table table thead > tr > th {
  text-align: left;
  padding: 5px 0px 5px 10px;
  background: #219d89;
  color: white;
}
.loan-info__main
  > .history
  > div
  > .account-table
  table
  thead
  > tr
  > th:first-child {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border: 1px solid #219d89;
}
.loan-info__main
  > .history
  > div
  > .account-table
  table
  thead
  > tr
  > th:last-child {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  border: 1px solid #219d89;
}
.loan-info__main > .history > div > .account-table table tbody > tr.invisible {
  display: none;
}
.loan-info__main > .history > div > .account-table table tbody > tr > td {
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
}
.loan-info__main > .history > div > .account-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.loan-info__main > .history > div > .account-pagination > .disabled {
  visibility: hidden;
}
.loan-info__tabs {
  width: 100%;
  display: flex;
  background: #61cab9;
  height: 80px;
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.loan-info__tabs:first-child {
  width: 50%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 80px;
}
.loan-info__tabs > .active-tab {
  background: #219d89 !important;
}
.loan-info__tabs > .active-tab > i {
  opacity: 1;
}
.loan-info__tabs > div {
  width: 50%;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loan-info__tabs > div > i {
  font-size: 50px;
  color: white;
  opacity: 0.5;
}

.information {
  display: flex;
  padding: 0 0 100px 0;
}
.information__left {
  background: #eeeeee;
  padding: 0 15px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  width: 250px;
}
.information__right {
  position: relative;
  overflow: hidden;
  flex: 1 1;
  border: 1px solid #eeeeee;
  padding: 0px 15px 40px 15px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.information__right--top {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex: 1 1;
}
.information__right--top > div:last-child {
  width: 300px;
  position: relative;
}
.information__right--top > div:last-child > div {
  height: 200px;
}
.information__right--top > div:last-child .score-labels {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-between;
  bottom: 5px;
  height: 20px !important;
  color: #757575;
}
.information__right--bottom {
  height: auto;
}
.information__right--bottom > div {
  height: 300px;
}
.information .powered {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
  padding: 10px 20px;
}
.information__item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.information__item > div:first-child {
  font-weight: 600;
  color: #219d89;
}

.standing {
  position: relative;
}
.standing__label {
  position: absolute;
  width: 250px;
  font-size: 18px;
  font-weight: 600;
}
.standing__step {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-bottom: 8px;
  width: 250px;
  border: 1px solid #eeeeee;
  border-radius: 3px;
}
.standing__step.highlight {
  background: #eeeeee;
}
.standing__step > div:first-child {
  width: 100px;
  text-align: right;
  padding: 5px 5px 5px 15px;
}
.standing__step:nth-child(1) > div:last-child {
  background: #96d258;
  color: white;
  padding: 5px 10px 5px 30px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.standing__step:nth-child(2) > div:last-child {
  background: #f8ba09;
  border: 0;
  color: white;
  padding: 5px 10px 5px 30px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.standing__step:nth-child(3) > div:last-child {
  background: #ff861e;
  color: white;
  padding: 5px 10px 5px 30px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.standing__step:nth-child(4) > div:last-child {
  background: #ff551a;
  color: white;
  padding: 5px 10px 5px 30px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.standing__step:nth-child(5) > div:last-child {
  background: #ff3d1c;
  color: white;
  padding: 5px 10px 5px 30px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.schedule__item {
  display: grid;
  grid-template-columns: 80px 80px 100px;
  grid-gap: 10px;
  margin-bottom: 10px;
}
.schedule__item > div > div:first-child {
  font-weight: 600;
  color: #219d89;
}

.showMore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0 10px 2%;
  flex-grow: 1;
  width: calc(100% * (1 / 4) - 80px);
  min-height: 50px;
  font-size: 13px;
  color: #324d5d;
  background-color: #3fb39d;
  padding: 25px 25px 25px 25px;
  border-radius: 5px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.05),
    0 6px 20px 0 rgba(0, 0, 0, 0.05) !important;
  cursor: pointer;
}
.showMore.hidden-option {
  display: none;
}
.showMore i {
  color: white;
  font-size: 45px;
}

.operational-dashboard {
  margin-left: auto;
  margin-right: auto;
  font-family: "Open Sans", "Helvetica Neue", Arial;
  padding: 25px;
  max-width: 1400px;
}

.loans {
  min-height: 685px;
  margin-top: 15px;
  padding-bottom: 40px;
}
.loans__header {
  display: grid;
  grid-template-columns: 5% 15% 10% 15% 5% 5% 15% 15% 10%;
  grid-gap: 10px;
  padding: 0px 25px 15px 25px;
  min-width: 950px;
}
.loans__header--id,
.loans__header--name,
.loans__header--date,
.loans__header--amount,
.loans__header--currbal,
.loans__header--nextpay,
.loans__header--comment,
.loans__header--referrer,
.loans__header--term {
  font-size: 12px;
  color: white;
  font-weight: 600;
}
.loans__body {
  display: flex;
  flex-direction: column;
  min-width: 950px;
  position: relative;
  font-size: 13px;
  color: #324d5d;
  background-color: #fff;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.05),
    0 6px 20px 0 rgba(0, 0, 0, 0.05) !important;
}
.loans__body .loader {
  padding: 10px;
}

#dashboard_headers {
  grid-template-columns: 5% 10% 10% 10% 10% 10% 15% 20%;
  grid-gap: 10px;
}

#dashboard_summary {
  grid-template-columns: 5% 10% 10% 10% 10% 10% 15% 20%;
}

.loan {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 5px;
  border-radius: 3px;
}
.loan:hover,
.loan .selected-loan {
  background-color: #f8f8f8;
}
.loan__summary {
  display: grid;
  grid-template-columns: 5% 15% 10% 15% 5% 5% 15% 15% 10%;
  grid-gap: 10px;
  align-items: center;
  padding: 15px 0 15px 0;
  border-radius: 3px;
}
.loan__summary--amount .amount.editing {
  display: none;
}
.loan__summary--term .term.editing {
  display: none;
}
.loan__summary--score {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.loan__summary--score div {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  z-index: 2;
}
.loan__summary--score div.green {
  background-color: #33c949;
}
.loan__summary--score div.yellow {
  background-color: #fdbd41;
}
.loan__summary--score div.red {
  background-color: #fd5652;
}
.loan__summary--score .marker {
  position: absolute;
  background: #eeeeee;
  width: 40px;
  height: 40px;
  z-index: 1;
}
.loan__summary--comment {
  padding: 0 30px 0 0;
  display: flex;
}
.loan__summary--comment .comment {
  position: relative;
  display: flex;
  height: 60px;
  align-items: center;
}
.loan__summary--comment .comment.editing {
  display: none;
}
.loan__summary--comment .comment__options {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80px;
}
.loan__summary--comment .comment__options button {
  min-width: 50px;
  height: 36px;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  background: #3fb39d !important;
  align-items: center;
}
.loan__summary--comment .comment__options i {
  font-size: 25px;
  color: white;
}
.loan__summary--comment .comment__options i.save {
  background: url("/save.svg");
  cursor: pointer;
}
.loan__summary--comment .comment__options i.cancel {
  background: url("/cancel.svg");
}
.loan__summary--comment i {
  font-size: 20px;
}
.loan__summary--comment input {
  padding: 10px 0px 10px 10px;
  color: #324d5d;
  width: 80%;
  border: 1px solid #a9a9a9;
  border-radius: 3px;
  background-color: white;
}
.loan__summary--id {
  font-weight: 600;
  color: #324d5d;
  cursor: pointer;
}
.loan__summary--id.returning {
  color: #e47a36;
}
.loan__summary--id.new {
  color: #219d89;
}
.loan__summary--referrer {
  padding: 0 30px 0 0;
  cursor: pointer;
}
.loan__summary--referrer select {
  background: white;
  border-radius: 3px;
  padding: 10px;
  color: #324d5d;
  width: 100%;
}
.loan__summary--name,
.loan__summary--date,
.loan__summary--ammount,
.loan__summary--comment {
  cursor: text;
}
.loan__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 0 15px 0;
}
.loan__actions form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.loan__actions--submit {
  display: flex;
}
.loan__action {
  position: relative;
  margin-bottom: 5px;
}
.loan__action button {
  width: 100px;
  height: 36px;
  color: white;
  border: none;
  font-weight: 600;
  cursor: pointer;
}
.loan__action button.sponsor {
  color: white !important;
  background-color: #3fb39d !important;
}
.loan__action button.sponsor i {
  display: none;
}
.loan__action button.deny {
  color: white !important;
  background-color: #f52606 !important;
}
.loan__action button.deny i {
  display: none;
}
.loan__info {
  height: 100%;
  min-height: 650px;
  background-color: #f8f8f8;
  color: #219d89;
  display: flex;
  padding-bottom: 20px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
}
.loan__info:not(.expanded) {
  display: none;
}
.loan__info--help {
  background-color: #f8f8f8;
  min-width: 120px;
  position: relative;
}
.loan__info--help.no-tip {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 200px;
}
.loan__info--help .tip {
  position: absolute;
  display: flex;
  top: 60px;
  left: 0;
  width: 100px;
  overflow: hidden;
  margin: 7px 10px;
  font-size: 12px;
  height: auto;
  white-space: pre-line;
}
.loan__info--help i {
  opacity: 0.05;
  font-size: 50px;
}
.loan__info #dashboard_indicators {
  width: 200px;
}
.loan__info--indicators {
  padding: 0 10px 0 10px;
  width: 185px;
  background: #eee;
}
.loan__info--indicators ul {
  padding: 0px 0px 0px 15px;
}
.loan__info--indicators ul p {
  font-size: 12px;
}
.loan__info--indicators ul li {
  list-style: none;
  background-image: none;
  background-repeat: none;
  background-position: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 0px;
}
.loan__info--indicators ul li ul li {
  float: right;
}
.loan__info--indicators ul li.average {
  margin-top: 30px;
}
.loan__info--indicators ul li .model {
  font-size: 12px;
  position: relative;
}
.loan__info--indicators ul li .model select {
  background: transparent;
  color: #219d89;
  border: 0;
  bottom: -7px;
  font-size: 10px;
  right: 0px;
  position: absolute;
  text-align-last: right;
}
.loan__info--indicators ul li .model select option {
  direction: rtl;
}
.loan__info--indicators ul li .tag {
  position: relative;
  color: #324d5d;
}
.loan__info--indicators ul li .red {
  color: #f52606;
}
.loan__info--indicators ul li .tag:first-child {
  font-size: 12px;
  font-weight: 600;
  max-width: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.loan__info--indicators ul li .tag:first-child i {
  opacity: 1;
  width: 10px;
  cursor: help;
  position: absolute;
  height: 10px;
  left: -15px;
  top: 0px;
  font-color: black;
}
.loan__info--indicators ul li .tag:first-child sub {
  position: absolute;
  font-size: 10px;
  bottom: 18px;
  font-weight: 500;
}
.loan__info--indicators ul li .db_tag {
  position: relative;
}
.loan__info--indicators ul li .orange {
  color: #e47a36;
}
.loan__info--indicators ul li .navy {
  color: #324d5d;
}
.loan__info--indicators ul li .db_tag:first-child {
  font-size: 12px;
  font-weight: 600;
  max-width: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.loan__info--indicators ul li .pie-chart {
  width: 100%;
  max-width: 100% !important;
}
.loan__info--charts {
  border: 1px solid #eeeeee;
  border-bottom-right-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
  background-color: #fff;
}
.loan__info--charts .graph_loading {
  width: 128px;
  margin: 136px auto;
}
.loan__info--charts > .info {
  padding: 0px 10px;
}
.loan__info--charts > .info > .graph {
  height: 450px;
  width: 95%;
  margin: 0px 0px 0px 10px;
}
.loan__info--charts > .info > .graph div {
  height: 400px;
}
.loan__info--charts > .info > .history {
  height: 400px;
  width: 95%;
  margin: 0px 0px 0px 10px;
}
.loan__info--charts > .info > .history > div {
  height: 350px;
}
.loan__info--charts > .info > .history > div > .account-selector {
  margin: 0 2px;
}
.loan__info--charts > .info > .history > div > .account-selector select {
  background: white;
  border-radius: 3px;
  padding: 7px;
  color: #324d5d;
  width: 100%;
}
.loan__info--charts > .info > .history > div > .account-table {
  margin-top: 5px;
}
.loan__info--charts > .info > .history > div > .account-table table {
  width: 100%;
  table-layout: fixed;
}
.loan__info--charts
  > .info
  > .history
  > div
  > .account-table
  table
  .payment_warning {
  background-color: #e51f43 !important;
  color: #ffffff !important;
}
.loan__info--charts
  > .info
  > .history
  > div
  > .account-table
  table
  .funding_warning {
  background-color: #f0ca3e !important;
}
.loan__info--charts > .info > .history > div > .account-table table .red {
  color: #f52606;
}
.loan__info--charts > .info > .history > div > .account-table table .green {
  color: #3fb39d;
}
.loan__info--charts > .info > .history > div > .account-table table .normal {
  color: #000000;
}
.loan__info--charts
  > .info
  > .history
  > div
  > .account-table
  table
  .nsfs_warning {
  color: purple;
}
.loan__info--charts > .info > .history > div > .account-table table .account {
  width: 150px;
}
.loan__info--charts > .info > .history > div > .account-table table .amount {
  width: 100px;
}
.loan__info--charts > .info > .history > div > .account-table table .location {
  width: 150px;
}
.loan__info--charts
  > .info
  > .history
  > div
  > .account-table
  table
  thead
  > tr
  > th {
  text-align: left;
  padding: 5px 0px 5px 10px;
  background: #219d89;
  color: white;
}
.loan__info--charts
  > .info
  > .history
  > div
  > .account-table
  table
  thead
  > tr
  > th:first-child {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border: 1px solid #219d89;
  width: 90px;
}
.loan__info--charts
  > .info
  > .history
  > div
  > .account-table
  table
  thead
  > tr
  > th:last-child {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  border: 1px solid #219d89;
  width: 90px;
}
.loan__info--charts
  > .info
  > .history
  > div
  > .account-table
  table
  tbody
  > tr.invisible {
  display: none;
}
.loan__info--charts
  > .info
  > .history
  > div
  > .account-table
  table
  tbody
  > tr
  > td {
  padding: 5px 0px 5px 10px;
  white-space: nowrap;
  overflow: hidden;
}
.loan__info--charts > .info > .history > div > .account-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.loan__info--charts > .info > .history > div > .account-pagination > .disabled {
  visibility: hidden;
}

.tabs {
  display: flex;
  width: auto;
  background: #61cab9;
  height: 80px;
}
.tabs > div {
  width: 50%;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tabs > div > i {
  font-size: 50px;
  color: white;
  opacity: 0.5;
}
.tabs > .active-tab {
  background: #219d89 !important;
}
.tabs > .active-tab > i {
  opacity: 1;
}

.hidden {
  display: none;
}

@media only screen and (max-width: 770px) {
  .sponsor,
  .deny {
    width: 40px !important;
    min-width: 40px !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .sponsor span,
  .deny span {
    display: none;
  }
  .sponsor i,
  .deny i {
    display: inline !important;
    font-size: 25px !important;
  }
}
@media only screen and (min-width: 771px) and (max-width: 1300px) {
  .sponsor,
  .deny {
    width: 40px !important;
    min-width: 40px !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .sponsor span,
  .deny span {
    display: none;
  }
  .sponsor i,
  .deny i {
    display: inline !important;
    font-size: 25px !important;
  }
}
.applications-body,
.dashboard-body {
  -background: radial-gradient(
    ellipse at center,
    #fffeea 0%,
    #6c8595 35%,
    #2d6183 100%
  );
  background: #35698b;
}

.header {
  display: flex;
  height: 120px;
}
.header.fixed {
  min-width: 950px;
}
.header .header-fig {
  display: flex;
  align-items: center;
  align-items: flex-start;
  width: 22%;
}
.header .header-fig__logo img {
  width: 70px;
}
.header .header-fig__titles {
  margin-left: 15px;
  color: white;
}
.header .header-fig__titles--title {
  font-weight: 600;
  font-size: 22px;
}
.header .header-fig__titles--subtitle {
  font-size: 18px;
}
.header .header-company {
  display: flex;
  width: 56%;
  justify-content: center;
}
.header .header-company img {
  height: auto;
  width: auto;
  max-height: 75%;
  max-width: 80%;
}
.header .navright {
  width: 22%;
}
.header .navright nav.nav-main a {
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
}
.header .navright nav.nav-main a:hover {
  color: #e47a36;
}
.header .navright a:hover {
  cursor: pointer;
}

.footer {
  padding-top: 40px;
  bottom: 10px;
  left: 0;
  font-weight: 600;
  color: white;
  text-align: center;
  width: 100%;
  font-size: 12px;
  z-index: 0;
}
.footer.fixed {
  position: fixed !important;
  padding-top: 40px !important;
  min-width: 950px !important;
}
.footer.inherit {
  position: inherit !important;
  padding-bottom: 25px !important;
  padding-top: 0 !important;
}

.applications-body h1,
.dashboard-body h1 {
  font-family: "Open Sans" !important;
  color: #fedf7f !important;
  font-size: 24px !important;
  line-height: 22px !important;
  font-weight: 600 !important;
}
.applications-body h2,
.dashboard-body h2 {
  margin-top: 10px;
  font-family: "Open Sans" !important;
  color: #219d89 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  font-weight: 600 !important;
}
.applications-body h3,
.dashboard-body h3 {
  font-family: "Open Sans" !important;
  color: #219d89 !important;
  font-size: 15px !important;
  line-height: 20px !important;
}
.applications-body h3.center,
.dashboard-body h3.center {
  text-align: center;
}
.applications-body h4,
.dashboard-body h4 {
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-align: center !important;
  line-height: 24px !important;
}

.bal-graph svg {
  background: white;
  height: 100%;
  width: 100%;
}
.bal-graph svg .tooltip {
  transition: all 100ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
}
.bal-graph svg .tooltip rect {
  height: 50px;
  rx: 5px;
}
.bal-graph svg .tooltip #tooltip-date {
  -webkit-transform: translate(5px, 17px);
          transform: translate(5px, 17px);
  font-weight: bold;
  font-size: 16px;
}
.bal-graph svg .tooltip #tooltip-balance {
  -webkit-transform: translate(5px, 40px);
          transform: translate(5px, 40px);
  font-size: 18px;
}
.bal-graph svg .grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
.bal-graph svg .grid path {
  stroke-width: 0;
}
.bal-graph svg .origin line {
  stroke: black;
}
.bal-graph svg .y-axis .tick text,
.bal-graph svg .x-axis .tick text {
  color: black;
  font-size: 15px;
}

/*# sourceMappingURL=glazed.css.map */

